import { providers } from "ethers"
import Web3 from "web3"

 // export const swapContract = "0xBc35bb3E293Fd944bffF365ACA12708ecF3Fc918" // defi testnet
  export const swapContract = "0x5336b0935f56fB972d5Bd2D78195deB708b7Cf48"; // defi mainnet

// TEST COIN  ADDRESS
// export const DWCAddress = "0x8e41D29D39f285b6b7e08D47fc4d9205Dd96a1d4" 
// export const USDTAddress = "0x9441C14C79c37f60F84DFc7C238E15C88489ACA0" 

// Main COIN  ADDRESS
 export const DWCAddress = "0x3C9F96E704392863a8040E033014f44dA84FAcca"; 
 export const USDTAddress = "0x55d398326f99059ff775485246999027b3197955"; 

export const apiSecret = process.env.REACT_APP_API_SECRET;

export const graphQlUrl =
  "https://api.studio.thegraph.com/query/76981/exbit-swap-api/version/latest"; 

export const transactionUrl = "https://bscscan.com/tx/" // mainnet


export const chainRPC =
  "https://multi-long-cherry.bsc.quiknode.pro/8d43112900e37757b306d3106998636423550b0b";  // mainnet link
export const defaultWeb3 = new Web3(chainRPC)
export const web3Provider = new providers.JsonRpcProvider(chainRPC);

export const defaultNetwork = 56
export const polygonchainRPC = "https://multi-long-cherry.bsc.quiknode.pro/8d43112900e37757b306d3106998636423550b0b"
export const appURL = "https://exbit-swap.netlify.app/"

export const tokenDetails = [{ address: DWCAddress, label: "DWC", value:"DWC" },
{ address: USDTAddress, label: "USDT",value:"USDT" }
]









